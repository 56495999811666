.agent-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.agent-buttons p {
    margin: 0;
}
@media (min-width: 600px) {
    .agent-buttons {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
    }
}

.contact-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    background-color: #1e2a3a;
    border-radius: 8px;
}

.contact-info-item {
    position: relative;
    padding: 10px;
    background-color: #2c3e50;
    border-radius: 4px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.contact-info-label {
    font-size: 0.8em;
    color: #a0aec0;
}

.contact-info-value {
    font-size: 0.9em;
    color: #e2e8f0;
    word-break: break-word;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1em;
    line-height: 1;
}

.edit-popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #34495e;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
}

.edit-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #2c3e50;
    color: #e2e8f0;
    border: 1px solid #4a5568;
}

.edit-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.save-button, .cancel-button {
    padding: 5px 10px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.save-button {
    background: #4CAF50;
}

.cancel-button {
    background: #f44336;
}

@media (max-width: 480px) {
    .contact-info {
        grid-template-columns: 1fr 1fr;
    }
    
    .contact-info-item {
        min-height: 50px;
        padding: 8px;
    }
    
    .contact-info-label {
        font-size: 0.7em;
    }
    
    .contact-info-value {
        font-size: 0.8em;
    }
}


.contact-info-textbox {
    width: 300px;
}

.custom-modal {
    width: 97% !important;
    max-width: 97% !important;
    height: 90vh !important;
    max-height: 90vh !important;
}

.custom-modal .modal-body {
    height: calc(90vh - 120px) !important;
    max-height: calc(90vh - 120px) !important;
    overflow-y: auto !important;
}

.custom-modal .modal-content {
    height: 100% !important;
}

.gradient-text {
    background: linear-gradient(to right, #00ffff, #ff00ff, #ed521f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-family: Arial, sans-serif;
    font-size: 30px !important;
    font-weight: bold;
}

.note-section {
    margin-top: 20px;
    position: relative;
}

.note-input {
    position: relative;
    padding: 10px;
    cursor: text;
    transition: all 0.3s ease;
}

.note-input textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
}

.note-input.expanded {
    padding: 20px;
    background: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.note-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}


.header-tagline {
    font-size: 12.5px;
    font-weight: normal
}


table.table-hover-effect tbody tr:hover {
    background-color: rgba(128, 0, 128, 0.16) !important;
    transition: background-color 0.0001s ease;
}